var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.deviceNotPaired
        ? _c(
            "v-row",
            { staticClass: "pt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-5",
                  attrs: { cols: "12", justify: "center", align: "center" },
                },
                [
                  _c("img", {
                    staticClass: "bitbox-img",
                    attrs: { src: _vm.lockedImg, width: "100%" },
                  }),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", justify: "center", align: "center" } },
                [
                  _c("mew-button", {
                    attrs: {
                      title: "Unlock BitBox02",
                      "color-theme": "primary",
                      "has-full-width": true,
                      "btn-size": "xlarge",
                      "btn-style": "background",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.unlockWallet.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceConnected
        ? _c(
            "v-row",
            { staticClass: "py-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", justify: "center", align: "center" } },
                [
                  _c("video", { attrs: { autoplay: "", loop: "" } }, [
                    _c("source", {
                      attrs: { src: _vm.videoPath, type: "video/mp4" },
                    }),
                    _vm._v(" Your browser does not support the video tag. "),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceInitialized
        ? _c(
            "v-row",
            { staticClass: "pt-4", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-5",
                  attrs: { cols: "12", justify: "center", align: "center" },
                },
                [
                  _c("img", {
                    staticClass: "bitbox-img",
                    attrs: { src: _vm.openImg, width: "100%" },
                  }),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", justify: "center", align: "center" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "border justify-center font-wrapping py-5 mb-8",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mew-heading-4 font-weight-medium pl-1",
                        },
                        [_vm._v("Loading....")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceUnpaired
        ? _c(
            "div",
            [
              _c("div", { staticClass: "mew-body" }, [
                _c("pre", { staticClass: "text-center mew-heading-1" }, [
                  _vm._v(_vm._s(_vm.devicePairingCode)),
                ]),
              ]),
              _c("mew-button", {
                staticClass: "mt-5",
                attrs: {
                  title: "Confirm",
                  "color-theme": "primary",
                  "has-full-width": true,
                  "btn-size": "xlarge",
                  "btn-style": "background",
                  disabled: !_vm.deviceConfirmed,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resolvePairing.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }